<template>
    <div>
        <span class="title">
            Search :
        </span>
        <span> 
            <b-form-input  
                placeholder="" 
                class=""
                @input="getCouponList"
                @keyup.enter="searchCouponList"
            >
            </b-form-input>
        </span>
        <span class="px-2">
            <b-iconstack font-scale="2" type="submit" @click="searchCouponList">
                <b-icon stacked icon="circle-fill" class="main-color"></b-icon>
                <b-icon stacked icon="search" scale="0.5" variant="white"></b-icon>
            </b-iconstack>
        </span>
    </div>
</template>

<script>
export default {
    methods:{
        getCouponList(event){
            this.$emit("onDataChange", event);
        },
        searchCouponList(){
            this.$emit("searchCouponList");
        }
    }
}
</script>

<style lang="scss" scoped>
.title{
    color: #4f5d73;
    font-weight: 600;
}
::v-deep .form-control{
    display: inline;
    width: auto;
}
</style>