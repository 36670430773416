<template>
  <div>
    <b-row>
      <b-col>
        <SearchCouponList
          @onDataChange="(val) => (filter.search = val)"
          @searchCouponList="searchPrivilegeList"
        />
      </b-col>
      <b-col class="text-right">
        <b-form-checkbox
          size="md"
          v-model="selectedDisplayOnly"
          @change="setShowOnlySelect()"
          >Display Only Selected</b-form-checkbox
        >
      </b-col>
    </b-row>
    <div class="my-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="list"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(id)="data">
          <b-form-radio
            v-model="form.privilege_id"
            :value="data.item.id"
            @change="setNewCodeData(data.item)"
          >
          </b-form-radio>
        </template>
        <template v-slot:cell(validFrom)="data">
          <div class="d-flex justify-content-center">
            {{ $moment(data.item.validFrom).format("DD/MM/YYYY hh:mm:ss A") }}
          </div>
        </template>
        <template v-slot:cell(validTo)="data">
          <div class="d-flex justify-content-center">
            {{ $moment(data.item.validTo).format("DD/MM/YYYY hh:mm:ss A") }}
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          class="form-inline justify-content-center justify-content-md-between"
        >
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing {{ showing }} to {{ showingTo }} of {{ rows }} entries
            </p>
          </b-col>
          <b-pagination
            v-model="filter.page"
            :total-rows="rows"
            :per-page="filter.take"
            @change="pagination"
            class="m-md-0"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import SearchCouponList from "@/components/coupon/SearchCouponList.vue";
export default {
  components: {
    SearchCouponList,
  },
  props: {
    list: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
    form: {
      required: true,
      type: Object,
    },
    rows: {
      required: true,
      type: Number,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    checkFirstShowOnly: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedDisplayOnly: false,
      fields: [
        {
          key: "id",
          label: "",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "text-center",
        },
        {
          key: "code",
          label: "Code",
        },
        {
          key: "validFrom",
          label: "Valid From",
        },
        {
          key: "validTo",
          label: "Valid To",
        },
      ],
      selected: [],
      showing: 1,
      showingTo: 5,
    };
  },
  created() {
    this.selectedDisplayOnly = this.checkFirstShowOnly;
  },
  methods: {
    setShowOnlySelect() {
      this.$emit("setShowDisplay", this.selectedDisplayOnly);
    },
    searchPrivilegeList() {
      this.$emit("searchPrivilegeList", this.filter.search);
    },
    setNewCodeData(item) {
      this.$emit("getIdSelectPrivilege", item.id);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.$emit("getPrivilegeListByPage", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped></style>
